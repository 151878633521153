export const BOARD_SIZE = 49;
export const WIN_LENGTH = 5;
export const PLAYER_1 = 1;
export const PLAYER_2 = 2;

export const STATUS_WAITING = 'hold';
export const STATUS_INIT = 'init';
export const STATUS_CHECK = 'check';
export const STATUS_PROCESS = 'process';
export const STATUS_BOT = 'bot';

const randomOffset = (maxOffset) => (Math.random() - 0.5) * maxOffset;
export const dirOffects = {
 Horizontal: {
     x1: 0,
     y1: 10,
     x2: 20,
     y2: 10,
 },
 Vertical: {
     x1: 10,
     y1: 0,
     x2: 10,
     y2: 20,
 },
 DiagonalBT: {
     x1: 3,
     y1: 20,
     x2: 17,
     y2: 0,
 },
 DiagonalTB: {
     x1: 3,
     y1: 0,
     x2: 17,
     y2: 20,
 },
}
const nullOffsets ={
    line1: {
        x1: 0,
            y1: 0,
            a1: 0,
            x2: 0,
            y2: 0,
            a2: 0,
    },
    line2: {
        x1: 0,
            y1: 0,
            a1: 0,
            x2: 0,
            y2: 0,
            a2: 0,
    },
    adjustedRadiusX: 0,
    adjustedRadiusY: 0,
    adjustedRotation: 0,
    x: 0,
    y: 0,
}

export const getXconfig = () => ({
    line1: {
        x1: randomOffset(3),
        y1: randomOffset(3),
        a1: randomOffset(2),
        x2: randomOffset(3),
        y2: randomOffset(3),
        a2: randomOffset(2),
    },
    line2: {
        x1: randomOffset(3),
        y1: randomOffset(3),
        a1: randomOffset(2),
        x2: randomOffset(3),
        y2: randomOffset(3),
        a2: randomOffset(2),
    }
});

export const getOconfig = () => ({
    adjustedRadiusX: randomOffset(2),
    adjustedRadiusY: randomOffset(2),
    adjustedRotation: randomOffset(0.2),
    x: randomOffset(3),
    y: randomOffset(3),
});

export const initBoard = () => Array
        .from({ length: BOARD_SIZE }, () =>
            Array(BOARD_SIZE ).fill(nullOffsets));

export const updatedBoard = (board, row, col, currentPlayer) =>
    board.map((r, i) =>
    i === row ? r.map((cell, j) => (j === col ? currentPlayer : cell)) : r
);

export const updateCells = (x, y, current, currentPlayer) => {
    const newDots = [...current];
    newDots[x][y] = {
        ...current[x][y],
        ...currentPlayer === PLAYER_1 ? getXconfig() : getOconfig(),
        player: currentPlayer
    };
    return newDots;
}

export const checkWinner = (board, row, col, player) => {
    const result = {
        player,
        direction: '',
        line: []
    };
   // console.log('---checkWinner', {row, col, player});
    const directions = [
        { dr: 1, dc: 0, direction: "Horizontal" }, // Horizontal
        { dr: 0, dc: 1, direction: "Vertical" }, // Vertical
        { dr: 1, dc: 1, direction: "DiagonalTB" }, // Diagonal /
        { dr: 1, dc: -1, direction: "DiagonalBT"}, // Diagonal \
    ];

    for (const { dr, dc, direction } of directions) {
        result.direction = direction;
        result.line = [{row, col}];
      //  console.log('---checkWinner direction', {direction});
        for (let step = 1; step < WIN_LENGTH; step++) {
            const r1 = row + dr * step;
            const c1 = col + dc * step;

        ///    console.log('checkWinner direction +', {direction}, {r1, c1} , board[r1][c1].player);
            if (r1 >= 0 && r1 < BOARD_SIZE && c1 >= 0 && c1 < BOARD_SIZE && board[r1] && board[r1][c1] && board[r1][c1].player === player) {
                result.line.push({row: r1, col: c1});
            } else {
                break;
            }
        }

        for (let step = 1; step < WIN_LENGTH; step++) {
            const r2 = row - dr * step;
            const c2 = col - dc * step;
      //      console.log('checkWinner direction -', {direction}, {r2, c2} , board[r2][c2].player);
            if (r2 >= 0 && r2 < BOARD_SIZE && c2 >= 0 && c2 < BOARD_SIZE && board[r2] && board[r2][c2] && board[r2][c2].player === player) {
                result.line.unshift({row: r2, col: c2});
            } else {
                break;
            }
        }
        // console.log('---checkWinner direction finish', {direction}, result.line, result.line.length);
        if (result.line.length >= WIN_LENGTH) break;
        else result.line.length = 0;
    }
    return result.line.length >= WIN_LENGTH ? result : false;
};

const SCORES = {
    "11111": 100000,  // Five in a row
    "011110": 10000,  // Open four
    "01110": 1000,    // Open three
    "0110": 100,      // Open two
    // Adjust scores as needed
};

function evaluateLine(line, player) {
    const str = line.map(cell => (cell === player ? "1" : cell === null ? "0" : "2")).join("");
    let score = 0;

    Object.keys(SCORES).forEach(pattern => {
        if (str.includes(pattern)) {
            score += SCORES[pattern];
        }
    });

    return score;
}

// function evaluateBoard2(board, player) {
//     let score = 0;
//
//     const checkDirection = (startRow, startCol, deltaRow, deltaCol) => {
//         let line = [];
//         let row = startRow;
//         let col = startCol;
//
//         while (row >= 0 && row < board.length && col >= 0 && col < board[0].length) {
//             line.push(board[row][col]);
//             row += deltaRow;
//             col += deltaCol;
//         }
//
//         score += evaluateLine(line, player);
//     };
//
//     // Check rows and columns
//     for (let i = 0; i < board.length; i++) {
//         checkDirection(i, 0, 0, 1); // Rows
//         checkDirection(0, i, 1, 0); // Columns
//     }
//
//     // Check diagonals
//     for (let i = 0; i < board.length; i++) {
//         checkDirection(i, 0, 1, 1); // Diagonal \
//         checkDirection(0, i, 1, 1);
//         checkDirection(i, board.length - 1, 1, -1); // Diagonal /
//         checkDirection(0, board.length - 1 - i, 1, -1);
//     }
//
//     return score;
// }
//
// function evaluateLine1(line, player) {
//     const str = line.map(cell => (cell === player ? "1" : cell === null ? "0" : "2")).join("");
//     let score = 0;
//
//     Object.keys(SCORES).forEach(pattern => {
//         if (str.includes(pattern)) {
//             score += SCORES[pattern];
//         }
//     });
//
//     return score;
// }

// const checkDirection1 = (board, player, startRow, startCol, deltaRow, deltaCol) => {
//     let line = [];
//     let row = startRow;
//     let col = startCol;
//
//     while (row >= 0 && row < BOARD_SIZE && col >= 0 && col < BOARD_SIZE) {
//         line.push(board[row][col]);
//         row += deltaRow;
//         col += deltaCol;
//     }
//
//     return evaluateLine1(line, player);
// };
//
// function evaluateBoard1(board, player) {
//     let score = 0;
//
//     // Check rows and columns
//     for (let i = 0; i < board.length; i++) {
//         score += checkDirection1(board, player, i, 0, 0, 1); // Rows
//         score += checkDirection1(board, player, 0, i, 1, 0); // Columns
//     }
//
//     // Check diagonals
//     for (let i = 0; i < board.length; i++) {
//         score += checkDirection1(board, player, i, 0, 1, 1); // Diagonal \
//         score += checkDirection1(board, player, 0, i, 1, 1);
//         score += checkDirection1(board, player, i, board.length - 1, 1, -1); // Diagonal /
//         score += checkDirection1(board, player, 0, board.length - 1 - i, 1, -1);
//     }
//
//     return score;
// }

function evaluateBoard(board, player) {
    let score = 0;

    const checkDirection = (startRow, startCol, deltaRow, deltaCol) => {
        let line = [];
        let row = startRow;
        let col = startCol;

        while (row >= 0 && row < board.length && col >= 0 && col < board[0].length) {
            line.push(board[row][col]);
            row += deltaRow;
            col += deltaCol;
        }

        score += evaluateLine(line, player);
    };

    // Check rows and columns
    for (let i = 0; i < board.length; i++) {
        checkDirection(i, 0, 0, 1); // Rows
        checkDirection(0, i, 1, 0); // Columns
    }

    // Check diagonals
    for (let i = 0; i < board.length; i++) {
        checkDirection(i, 0, 1, 1); // Diagonal \
        checkDirection(0, i, 1, 1);
        checkDirection(i, board.length - 1, 1, -1); // Diagonal /
        checkDirection(0, board.length - 1 - i, 1, -1);
    }

    return score;
}

function getPossibleMoves(board) {
    const moves = new Set();

    for (let row = 0; row < board.length; row++) {
        for (let col = 0; col < board[row].length; col++) {
            if (board[row][col] !== null) {
                // Add neighbors
                for (let dr = -1; dr <= 1; dr++) {
                    for (let dc = -1; dc <= 1; dc++) {
                        const r = row + dr;
                        const c = col + dc;

                        if (r >= 0 && r < board.length && c >= 0 && c < board[0].length && board[r][c] === null) {
                            moves.add(`${r},${c}`);
                        }
                    }
                }
            }
        }
    }
    // console.log('getPossibleMoves', {moves});
    return Array.from(moves).map(move => move.split(",").map(Number));
}

// function getPossibleMoves2(board) {
//     const moves = new Set();
//
//     for (let row = 0; row < board.length; row++) {
//         for (let col = 0; col < board[row].length; col++) {
//             if (board[row][col] !== null) {
//                 // Add neighbors
//                 for (let dr = -1; dr <= 1; dr++) {
//                     for (let dc = -1; dc <= 1; dc++) {
//                         const r = row + dr;
//                         const c = col + dc;
//
//                         if (r >= 0 && r < board.length && c >= 0 && c < board[0].length && board[r][c] === null) {
//                             moves.add(`${r},${c}`);
//                         }
//                     }
//                 }
//             }
//         }
//     }
//
//     const res = Array.from(moves).map(move => move.split(",").map(Number));
//     console.log('getPossibleMoves', {res});
//     return res;
// }

// function getPossibleMoves1(board) {
//     const moves = {};
//     console.log('getPossibleMoves1', {board});
//     const logic = Object.keys(board).map(row => {
//         const cells = board[row] ? Object.keys(board[row]) : [];
//         return cells.map(col => {
//             for (let dr = -1; dr <= 1; dr++) {
//                 for (let dc = -1; dc <= 1; dc++) {
//                     const r = +row + dr;
//                     const c = +col + dc;
//
//                     if (r >= 0 && r < BOARD_SIZE && c >= 0 && c < BOARD_SIZE && (!board[r]||!board[r][c])) {
//                        if (!moves[r]) moves[r] = {};
//                        if (!moves[r][c]) moves[r][c] = true;
//                     }
//                 }
//             }
//         });
//     });
//     return Object.keys(moves)
//         .reduce((a, x) =>  [...a, ...Object.keys(moves[x]).map(y=> [x, y])], []);
// }

function minimax(board, depth, isMaximizing, alpha, beta, player, opponent) {
    if (depth === 0) {
        return evaluateBoard(board, player) - evaluateBoard(board, opponent);
    }

    const moves = getPossibleMoves(board);
    let bestScore = isMaximizing ? -Infinity : Infinity;

    for (const [row, col] of moves) {
        board[row][col] = isMaximizing ? player : opponent;

        const score = minimax(
            board,
            depth - 1,
            !isMaximizing,
            alpha,
            beta,
            player,
            opponent
        );

        board[row][col] = null;

        if (isMaximizing) {
            bestScore = Math.max(bestScore, score);
            alpha = Math.max(alpha, score);
        } else {
            bestScore = Math.min(bestScore, score);
            beta = Math.min(beta, score);
        }

        if (beta <= alpha) break;
    }

    return bestScore;
}

export async function getBestMove(board, player, opponent, depth = 3) {
    return new Promise((resolve, reject) => {
        try {
            const bestMove = { row: null, col: null };
            let bestScore = -Infinity;
            // getPossibleMoves1(board1);
            // getPossibleMoves2(board);
            for (const [row, col] of getPossibleMoves(board)) {
                board[row][col] = player;

                const score = minimax(board, depth - 1, false, -Infinity, Infinity, player, opponent);

                board[row][col] = null;

                if (score > bestScore) {
                    bestScore = score;
                    // bestMove = [row, col];
                    bestMove.row = row;
                    bestMove.col = col;
                }
            }
            // console.log('resolve', {bestMove});
            resolve(bestMove);
        } catch (error) {
            reject(error);
        }
    });
}

export const getNearest = (board, last) => {
    let offCol = -1;
    let offRow = -1;
    let maxOff = 1;
    let i = 1000;
    while (i > 0) {
        if (board[last.row + offRow]
            && board[last.row + offRow][ last.col + offCol]
            && !board[last.row + offRow][ last.col + offCol].player) {
            return {row: last.row + offRow, col: last.col + offCol};
        }
        offCol++;
        if (offCol === maxOff && offRow === maxOff ) maxOff++;
        if (offCol > maxOff) {
            offCol = -1 * maxOff;
            offRow++;
            if (offRow > maxOff) {
                offRow = -1 * maxOff;
            }
        }
        i--;
    }
}

export async function getBestMoveEmu(board, last) {
    console.log({last});
    return new Promise((resolve, reject) => {
        try {
            // setTimeout(()=>{
                resolve(getNearest(board, last))
            // }, 200);
        } catch (error) {
            reject(error);
        }
    })
}
