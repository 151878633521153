const createWorker = () => new Worker(new URL("../workers/opponent.js", import.meta.url));
const worker = createWorker();
export const runWorker = ( data ) =>{
    return new Promise((resolve, reject) => {
        worker.onmessage = (event) => {
            resolve(event.data); // Resolve the promise with the result
           // worker.terminate(); // Terminate the worker
        };

        worker.onerror = (error) => {
            console.error({error});
            reject(error); // Reject the promise in case of an error
          //  worker.terminate(); // Terminate the worker
        };

        worker.postMessage(data); // Send data to the worker
    });
}