import {PLAYER_1, dirOffects} from "./gomoku";

const gridSize = 50;
const dotRadius = 1;
const player1Color = "rgba(0, 0, 255, 0.7)";
const player2Color = "rgba(255, 0, 0, 0.7)";
const lineColor = "rgba(0, 0, 255, 0.2)";
export const clickedZoneRaduis = 5;

const margin = 10;
export const boxSize = 1000;
const boxLSize = 300;


export const initializeGrid = () => {
    const newDots = [];
    for (let x = 0; x < gridSize; x++) {
        for (let y = 0; y < gridSize; y++) {
            newDots.push({
                x: margin + x * (boxSize - margin * 2) / (gridSize - 1),
                y: margin + y * (boxSize - margin * 2) / (gridSize - 1),
                offsets: {
                    line1: {
                        x1: 0,
                        y1: 0,
                        a1: 0,
                        x2: 0,
                        y2: 0,
                        a2: 0,
                    },
                    line2: {
                        x1: 0,
                        y1: 0,
                        a1: 0,
                        x2: 0,
                        y2: 0,
                        a2: 0,
                    },
                    adjustedRadiusX: 0,
                    adjustedRadiusY: 0,
                    adjustedRotation: 0,
                    x: 0,
                    y: 0,
                },
            });
        }
    }
    return newDots;
};


function crumpleEffect(imageData) {
    const data = imageData.data;
    const noiseStrength = 2;
    const distortionAmount = 10;

    for (let i = 0; i < data.length; i += 4) {
        const x = (i / 4) % boxSize;
        const y = Math.floor(i / 4 / boxSize);

        const dx = Math.random() * noiseStrength - noiseStrength / 2;
        const dy = Math.random() * noiseStrength - noiseStrength / 2;

        const newX = Math.min(Math.max(x + dx, 0), boxSize - 1);
        const newY = Math.min(Math.max(y + dy, 0), boxSize - 1);

        const newIndex = (newY * boxSize + newX) * 4;

        data[i] = data[newIndex];
        data[i + 1] = data[newIndex + 1];
        data[i + 2] = data[newIndex + 2];
        data[i + 3] = data[newIndex + 3];
    }

    return imageData;
}

function drawCrumpledPaper(ctx) {
    ctx.fillStyle = '#eaead7';
    ctx.fillRect(0, 0, boxSize, boxSize);

    const img = new Image();
    // img.src = 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/85/Old_aged_paper_texture.jpg/1024px-Old_aged_paper_texture.jpg'; // Пример текстуры
    img.onload = () => {
        ctx.drawImage(img, 0, 0, boxSize, boxSize);

        const imageData = ctx.getImageData(0, 0, boxSize, boxSize);

        const crumpledImageData = crumpleEffect(imageData);

        ctx.putImageData(crumpledImageData, 0, 0);
    };
}

const generateHandDrawnLine = (x1, y1, x2, y2, segments, maxOffset) => {
    const points = [];
    for (let i = 0; i <= segments; i++) {
        const t = i / segments;
        const x = x1 + (x2 - x1) * t + (Math.random() - 0.1) * maxOffset;
        const y = y1 + (y2 - y1) * t + (Math.random() - 0.1) * maxOffset;
        points.push({ x, y });
    }
    return points;
}

export const drawHandDrawnLine = (ctx, x1, y1, x2, y2, player) => {
    const points = generateHandDrawnLine( x1, y1, x2, y2, 20, 1);
    ctx.beginPath();
    ctx.moveTo(points[0].x, points[0].y);
    for (let i = 1; i < points.length; i++) {
        ctx.lineTo(points[i].x, points[i].y);
    }
    ctx.strokeStyle = player === PLAYER_1 ? player1Color : player2Color;
    ctx.lineWidth = 3;
    ctx.stroke();
    ctx.closePath();
}

const drawSymbol = (ctx, x, y, size, color, offsets) => {

    ctx.beginPath();
    if (color === player1Color) {
        ctx.moveTo(x + offsets.line1.a1 + offsets.line1.x1, y + offsets.line1.y1); // Start point with offset
        ctx.lineTo(x - offsets.line1.a2 + size + offsets.line1.x2, y + size + offsets.line1.y2); // End point with offset
        ctx.moveTo(x + offsets.line2.a1 + size + offsets.line2.x1, y  + offsets.line2.y1);
        ctx.lineTo(x - offsets.line2.a2 + offsets.line2.x2, y + size + offsets.line2.y2);
    } else {
        ctx.ellipse(
            x + 5 + offsets.x,
            y + 5 + offsets.y,
            4 + offsets.adjustedRadiusX,
            6 + offsets.adjustedRadiusY,
            0.5 + offsets.adjustedRotation,
            0,
            Math.PI * 2
        );
    }
    ctx.strokeStyle = color;
    ctx.lineWidth = 2;
    ctx.lineCap = "round";
    ctx.stroke();
    ctx.closePath();
}

const drawGridLines = (ctx, canvas) => {
    ctx.beginPath();
    for (let i = 0; i < gridSize; i++) {
        ctx.moveTo(margin + i * 20, 0);
        ctx.lineTo(margin + i * 20, canvas.height);
        ctx.moveTo(0, margin + i * 20);
        ctx.lineTo(canvas.width, margin + i * 20);
    }
    ctx.strokeStyle = lineColor;
    ctx.lineWidth = 1;
    ctx.stroke();
    ctx.closePath();
}

const drawSymbols = (ctx, board) => {
    const lines = Object.keys(board);
    lines.forEach(x => {
        const cells = Object.keys(board[x]);
        cells.forEach(( y) => {
            const posX = +x * 20 + 10;
            const posY = +y * 20 + 10;
            if (board[x][y].player) {
                drawSymbol(ctx, posX + 5, posY + 5, 10, board[x][y].player === PLAYER_1 ? player1Color : player2Color, board[x][y]);
            }
        });
    });
}

const drawWinnerLine = (ctx, winner) => {
    if (winner) {
        const first = winner.line[0];
        const last = winner.line[winner.line.length-1];

        drawHandDrawnLine(ctx,
            first.row * 20 + 10 + dirOffects[winner.direction].x1,
            first.col * 20 + 10 + dirOffects[winner.direction].y1,
            last.row * 20 + 10 + dirOffects[winner.direction].x2,
            last.col * 20 + 10 + dirOffects[winner.direction].y2,
            winner.player
        );
    }
}

const drawSquareBlock = (ctx, x, y) => {
    ctx.beginPath();
    ctx.moveTo(x-10, y);
    ctx.lineTo(x+10, y);
    ctx.moveTo(x, y-10);
    ctx.lineTo(x, y+10);
    ctx.strokeStyle = lineColor;
    ctx.lineWidth = 1;
    ctx.stroke();
    ctx.closePath();
}

const drawDot = (ctx, x, y, color) => {
    const dotSelectedRadius = 3;
    ctx.beginPath();
    ctx.arc(x, y, dotSelectedRadius, 0, Math.PI * 2);
    ctx.fillStyle = color;
    ctx.fill();
    ctx.closePath();
}

export const drawGridWithDots = (canvas, dots) => {
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    drawCrumpledPaper(ctx);
    // lines.forEach((line) => {
    //     ctx.beginPath();
    //     ctx.moveTo(line.start.x, line.start.y);
    //     ctx.lineTo(line.end.x, line.end.y);
    //     ctx.strokeStyle = line.player === 1 ? "blue" : "red";
    //     ctx.lineWidth = 2;
    //     ctx.stroke();
    //     ctx.closePath();
    // });

    dots.forEach((dot) => {
        drawSquareBlock(ctx, dot.x, dot.y);
        if (dot.player) {
            drawDot(ctx, dot.x, dot.y, dot.player === 1 ? player1Color : player2Color)
        }
    });

    // boxes.forEach((box) => {
    //     ctx.fillStyle =
    //         box.player === 1 ? player1Color : player2Color;
    //     ctx.fillRect(box.x, box.y, box.size, box.size);
    // });
};

export const drawGridBoard = async (canvas, board = null, winner = null) => {
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    drawCrumpledPaper(ctx);
    drawGridLines(ctx, canvas);
    if (board) drawSymbols(ctx, board);
    if (winner) drawWinnerLine(ctx, winner);
};

const isValidConnection = (lines, dot1, dot2) => {
    if (
        Math.abs(dot1.x - dot2.x) + Math.abs(dot1.y - dot2.y) ===
        (boxSize - margin * 2) / (gridSize - 1)
    ) {
        return !lines.some(
            (line) =>
                (line.start === dot1 && line.end === dot2) ||
                (line.start === dot2 && line.end === dot1)
        );
    }
    return false;
};

const checkSquares = (lines, line) => {
    let completed = 0;
    const squareSize = (boxSize - margin * 2) / (gridSize - 1);

    const possibleSquares = [
        {
            x: Math.min(line.start.x, line.end.x),
            y: Math.min(line.start.y, line.end.y),
        },
        {
            x: Math.max(line.start.x, line.end.x) - squareSize,
            y: Math.max(line.start.y, line.end.y) - squareSize,
        },
    ];

    possibleSquares.forEach((square) => {
        const squareLines = [
            { x1: square.x, y1: square.y, x2: square.x + squareSize, y2: square.y },
            {
                x1: square.x,
                y1: square.y,
                x2: square.x,
                y2: square.y + squareSize,
            },
            {
                x1: square.x + squareSize,
                y1: square.y,
                x2: square.x + squareSize,
                y2: square.y + squareSize,
            },
            {
                x1: square.x,
                y1: square.y + squareSize,
                x2: square.x + squareSize,
                y2: square.y + squareSize,
            },
        ];

        if (
            squareLines.every((sl) =>
                lines.some(
                    (line) =>
                        (line.start.x === sl.x1 &&
                            line.start.y === sl.y1 &&
                            line.end.x === sl.x2 &&
                            line.end.y === sl.y2) ||
                        (line.start.x === sl.x2 &&
                            line.start.y === sl.y2 &&
                            line.end.x === sl.x1 &&
                            line.end.y === sl.y1)
                )
            )
        ) {
            // setBoxes((prev) => [
            //     ...prev,
            //     { ...square, size: squareSize, player: currentPlayer },
            // ]);
            // setScores((prev) => {
            //     const newScores = [...prev];
            //     newScores[currentPlayer - 1]++;
            //     return newScores;
            // });
            completed++;
        }
    });

    return completed;
};

// const addLine = (lines, dot1, dot2) => {
//     setLines((prev) => [
//         ...prev,
//         { start: dot1, end: dot2, player: currentPlayer },
//     ]);
//     const completedSquares = checkSquares(lines, { start: dot1, end: dot2 });
//
//     if (completedSquares === 0) {
//         setCurrentPlayer((prev) => (prev === 1 ? 2 : 1));
//     }
// };

export const shuffle = (value) => {
    const newValue1= (Math.random() > 0.5) ? 0 : null;
    const newValue2= (Math.random() > 0.5) ? 0 : null;
    const newValue3= newValue2 === 0 ? null : 0;
    return {
        left: newValue1,
        top: (value.left === newValue1 && value.top === newValue2) ? newValue3 : newValue2,
        right: newValue1 === 0 ? null : 0,
        bottom: (value.left === newValue1 && value.top === newValue2) ? newValue2 : newValue3,
    };
};

export const jumpOut = (mouseX, mouseY, boxRect, box, position) => {
    if ((mouseX < boxSize / 2) && (position.left === 0)) {
        if ((mouseY < boxSize / 2) && (position.top === 0))  //left top
            if ((mouseX < (box.right - box.left + 20)) && (mouseY < (box.bottom - box.top + 20)))
                return true;

        if ((mouseY > boxSize / 2) && (position.bottom === 0))  //left bottom
            if ((mouseX < (box.right - box.left + 20)) && (mouseY > (box.top - boxRect.top - 20)))
                return true;
    }
    if ((mouseX > boxSize / 2) && (position.right === 0)) {
        if ((mouseY < boxSize / 2) && (position.top === 0))  //right top
            if ((mouseX > (box.left - boxRect.left - 20)) && (mouseY < (box.bottom - box.top + 20)))
                return true;

        if ((mouseY > boxSize / 2) && (position.bottom === 0))  //right bottom
            if ((mouseX > box.left - boxRect.left - 20) && (mouseY > box.top - boxRect.top - 20))
                return true;
    }
    return false;
}